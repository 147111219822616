import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-4 sm:px-0 flex justify-between items-end border-b dark:border-gray-900 mb-6" }
const _hoisted_2 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalTabs = _resolveComponent("BalTabs")!
  const _component_Activities = _resolveComponent("Activities")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_BalTabs, {
          modelValue: _ctx.activeTab,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeTab = $event)),
          tabs: _ctx.tabs,
          "no-pad": "",
          class: "-mb-px"
        }, null, 8, ["modelValue", "tabs"])
      ])
    ]),
    (_ctx.activeTab === _ctx.PoolActivityTab.ALL_ACTIVITY)
      ? (_openBlock(), _createBlock(_component_Activities, {
          key: 0,
          "pool-activity-type": _ctx.PoolActivityTab.ALL_ACTIVITY,
          pool: _ctx.pool,
          loading: _ctx.loading
        }, null, 8, ["pool-activity-type", "pool", "loading"]))
      : _createCommentVNode("", true),
    (_ctx.activeTab === _ctx.PoolActivityTab.USER_ACTIVITY)
      ? (_openBlock(), _createBlock(_component_Activities, {
          key: 1,
          "pool-activity-type": _ctx.PoolActivityTab.USER_ACTIVITY,
          pool: _ctx.pool,
          loading: _ctx.loading
        }, null, 8, ["pool-activity-type", "pool", "loading"]))
      : _createCommentVNode("", true)
  ], 64))
}