import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = {
  key: 0,
  id: "lineChartHeader",
  class: "mb-4"
}
const _hoisted_3 = { class: "text-gray-800 text-xl tracking-wider" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_ECharts = _resolveComponent("ECharts")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
        key: 0,
        class: "h-96 mt-16"
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.showHeader)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.currentValue), 1),
              _createElementVNode("span", {
                class: _normalizeClass({
          'text-green-400': _ctx.change >= 0,
          'text-red-400': _ctx.change < 0,
          'font-medium': true
        })
              }, _toDisplayString(_ctx.numeral(_ctx.change).format('+0.0%')), 3)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_ECharts, {
          ref: "chartInstance",
          class: _normalizeClass([_ctx.height ? `h-${_ctx.height}` : '', 'w-full']),
          option: _ctx.chartConfig,
          autoresize: "",
          onUpdateAxisPointer: _ctx.handleAxisMoved,
          "update-options": { replaceMerge: 'series' }
        }, null, 8, ["class", "option", "onUpdateAxisPointer"])
      ]))
}