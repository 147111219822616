import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col pt-1"
}
const _hoisted_2 = { class: "flex items-center justify-between text-sm text-gray-500 leading-none" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "font-numeric mr-2" }
const _hoisted_5 = {
  key: 0,
  class: "text-blue-500 lowercase"
}
const _hoisted_6 = {
  key: 1,
  class: "text-gray-400 dark:text-gray-600 lowercase"
}
const _hoisted_7 = {
  key: 2,
  class: "font-numeric"
}

import { HtmlInputEvent } from '@/types';
import { ref, computed, watchEffect } from 'vue';
import useTokens from '@/composables/useTokens';
import TokenSelectInput from '@/components/inputs/TokenSelectInput/TokenSelectInput.vue';
import useNumbers from '@/composables/useNumbers';
import { bnum } from '@/lib/utils';
import useUserSettings from '@/composables/useUserSettings';
import { isPositive, isLessThanOrEqualTo } from '@/lib/utils/validations';
import { useI18n } from 'vue-i18n';
import useWeb3 from '@/services/web3/useWeb3';

/**
 * TYPES
 */
type InputValue = string | number;

type Props = {
  amount: InputValue;
  address?: string;
  weight?: number;
  noRules?: boolean;
  noMax?: boolean;
  priceImpact?: number;
  label?: string;
  fixedToken?: boolean;
  customBalance?: string;
  disableMax?: boolean;
  hideInCurrency?: boolean;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    amount: { type: [String, Number], required: true, default: '' },
    address: { type: String, required: false, default: '' },
    weight: { type: Number, required: false, default: 0 },
    noRules: { type: Boolean, required: false, default: false },
    noMax: { type: Boolean, required: false, default: false },
    priceImpact: { type: Number, required: false },
    label: { type: String, required: false },
    fixedToken: { type: Boolean, required: false, default: false },
    customBalance: { type: String, required: false },
    disableMax: { type: Boolean, required: false, default: false },
    hideInCurrency: { type: Boolean, required: false, default: false }
  } as unknown as undefined,
  emits: ["blur", "input", "update:amount", "update:address", "update:isValid", "keydown"] as unknown as undefined,
  setup(__props: {
  amount: InputValue;
  address?: string;
  weight?: number;
  noRules?: boolean;
  noMax?: boolean;
  priceImpact?: number;
  label?: string;
  fixedToken?: boolean;
  customBalance?: string;
  disableMax?: boolean;
  hideInCurrency?: boolean;
}, { emit }: { emit: ({
  (e: 'blur', value: string): void;
  (e: 'input', value: string): void;
  (e: 'update:amount', value: string): void;
  (e: 'update:address', value: string): void;
  (e: 'update:isValid', value: boolean): void;
  (e: 'keydown', value: HtmlInputEvent);
}), expose: any, slots: any, attrs: any }) {

const props = __props




/**
 * STATE
 */
const _amount = ref<InputValue>('');
const _address = ref<string>('');
const ETH_BUFFER = 0.1;

/**
 * COMPOSABLEs
 */
const { getToken, balanceFor, nativeAsset } = useTokens();
const { fNum, toFiat } = useNumbers();
const { currency } = useUserSettings();
const { t } = useI18n();
const { isWalletReady } = useWeb3();

/**
 * COMPUTED
 */
const hasToken = computed(() => !!_address.value);
const hasAmount = computed(() => bnum(_amount.value).gt(0));
const hasBalance = computed(() => bnum(tokenBalance.value).gt(0));
const isMaxed = computed(() => _amount.value === tokenBalance.value);

const tokenBalance = computed(() => {
  if (props.customBalance) return props.customBalance;
  return balanceFor(_address.value);
});

const token = computed(() => {
  if (!hasToken.value) return {};
  return getToken(_address.value);
});

const tokenValue = computed(() => {
  return toFiat(_amount.value, _address.value);
});

const rules = computed(() => {
  if (!hasToken.value || !isWalletReady.value || props.noRules)
    return [isPositive()];
  return [
    isPositive(),
    isLessThanOrEqualTo(tokenBalance.value, t('exceedsBalance'))
  ];
});

const maxPercentage = computed(() => {
  if (!hasBalance.value || !hasAmount.value) return '0';

  return bnum(_amount.value)
    .div(tokenBalance.value)
    .times(100)
    .toFixed(1);
});

const barColor = computed(() => {
  if (bnum(_amount.value).gt(tokenBalance.value)) return 'red';
  return 'green';
});

const priceImpactSign = computed(() =>
  (props.priceImpact || 0) >= 0 ? '-' : '+'
);

const priceImpactClass = computed(() =>
  (props.priceImpact || 0) >= 0.01 ? 'text-red-500' : ''
);

/**
 * METHODS
 */
const setMax = () => {
  if (props.disableMax) return;

  if (_address.value === nativeAsset.address) {
    // Subtract buffer for gas
    _amount.value = bnum(tokenBalance.value).gt(ETH_BUFFER)
      ? bnum(tokenBalance.value)
          .minus(ETH_BUFFER)
          .toString()
      : '0';
  } else {
    _amount.value = tokenBalance.value;
  }

  emit('update:amount', _amount.value);
};

/**
 * CALLBACKS
 */
watchEffect(() => {
  _amount.value = props.amount;
  _address.value = props.address;
});

return (_ctx: any,_cache: any) => {
  const _component_BalProgressBar = _resolveComponent("BalProgressBar")!
  const _component_BalTextInput = _resolveComponent("BalTextInput")!

  return (_openBlock(), _createBlock(_component_BalTextInput, _mergeProps({
    modelValue: _amount.value,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_amount.value = $event)),
    placeholder: "0.0",
    type: "number",
    label: __props.label,
    decimalLimit: _unref(token)?.decimals || 18,
    rules: _unref(rules),
    validateOn: "input",
    autocomplete: "off",
    autocorrect: "off",
    step: "any",
    spellcheck: "false"
  }, _ctx.$attrs, {
    inputAlignRight: "",
    onBlur: _cache[3] || (_cache[3] = ($event: any) => (emit('blur', $event))),
    onInput: _cache[4] || (_cache[4] = ($event: any) => (emit('input', $event))),
    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (emit('update:amount', $event))),
    "onUpdate:isValid": _cache[6] || (_cache[6] = ($event: any) => (emit('update:isValid', $event))),
    onKeydown: _cache[7] || (_cache[7] = ($event: any) => (emit('keydown', $event)))
  }), {
    prepend: _withCtx(() => [
      _createVNode(TokenSelectInput, {
        modelValue: _address.value,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => (_address.value = $event)),
          _cache[1] || (_cache[1] = ($event: any) => (emit('update:address', $event)))
        ],
        fixed: __props.fixedToken,
        weight: __props.weight,
        class: "mr-2"
      }, null, 8, ["modelValue", "fixed", "weight"])
    ]),
    footer: _withCtx(() => [
      (_unref(isWalletReady) || (_unref(hasAmount) && _unref(hasToken)))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (!_unref(isWalletReady))
                ? (_openBlock(), _createElementBlock("div", _hoisted_3))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "cursor-pointer",
                    onClick: setMax
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('balance')) + ": ", 1),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(fNum)(_unref(tokenBalance), 'token')), 1),
                    (_unref(hasBalance) && !__props.noMax && !__props.disableMax)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          (!_unref(isMaxed))
                            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('max')), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('maxed')), 1))
                        ], 64))
                      : _createCommentVNode("", true)
                  ])),
              (_unref(hasAmount) && _unref(hasToken) && !__props.hideInCurrency)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createTextVNode(_toDisplayString(_unref(fNum)(_unref(tokenValue), _unref(currency))) + " ", 1),
                    (__props.priceImpact)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: _normalizeClass(_unref(priceImpactClass))
                        }, " (" + _toDisplayString(_unref(priceImpactSign) + _unref(fNum)(__props.priceImpact, 'percent')) + ") ", 3))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ]),
            (_unref(hasBalance) && !__props.noMax)
              ? (_openBlock(), _createBlock(_component_BalProgressBar, {
                  key: 0,
                  width: _unref(maxPercentage),
                  color: _unref(barColor),
                  class: "mt-2"
                }, null, 8, ["width", "color"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16, ["modelValue", "label", "decimalLimit", "rules"]))
}
}

})