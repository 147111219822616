import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, withCtx as _withCtx, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "p-4 border-t dark:border-gray-900" }
const _hoisted_2 = { class: "border dark:border-gray-900 rounded-lg shadow-inner p-2" }
const _hoisted_3 = { class: "flex items-center justify-between mb-3 text-sm text-gray-600" }
const _hoisted_4 = ["textContent"]
const _hoisted_5 = { class: "flex items-end" }
const _hoisted_6 = { class: "mr-2 text-lg font-medium w-1/2 leading-none break-words" }
const _hoisted_7 = { class: "px-4 py-3 bg-gray-50 dark:bg-gray-850 border-t dark:border-gray-900 border-b" }
const _hoisted_8 = { class: "flex items-center justify-between" }
const _hoisted_9 = { class: "w-1/2 flex items-center" }
const _hoisted_10 = { class: "w-3/4 flex flex-col leading-none" }
const _hoisted_11 = { class: "break-words" }
const _hoisted_12 = { class: "text-xs text-gray-400 break-words" }
const _hoisted_13 = { class: "w-1/2 flex flex-col leading-none text-right pl-2" }
const _hoisted_14 = { class: "break-words" }
const _hoisted_15 = {
  key: 0,
  class: "text-xs text-gray-400"
}
const _hoisted_16 = {
  key: 1,
  class: "px-4 pt-6 border-t border-b dark:border-gray-900"
}
const _hoisted_17 = { class: "p-4" }
const _hoisted_18 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormTypeToggle = _resolveComponent("FormTypeToggle")!
  const _component_BalRangeInput = _resolveComponent("BalRangeInput")!
  const _component_BalAsset = _resolveComponent("BalAsset")!
  const _component_TokenInput = _resolveComponent("TokenInput")!
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalTooltip = _resolveComponent("BalTooltip")!
  const _component_BalCheckbox = _resolveComponent("BalCheckbox")!
  const _component_BalForm = _resolveComponent("BalForm")!
  const _component_transactions_preview_modal = _resolveComponent("transactions-preview-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BalForm, {
      ref: "withdrawForm",
      onOnSubmit: _ctx.submit
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FormTypeToggle, {
          modelValue: _ctx.withdrawType,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.withdrawType = $event)),
          "form-types": _ctx.formTypes,
          loading: _ctx.loading
        }, null, 8, ["modelValue", "form-types", "loading"]),
        (_ctx.isProportional)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("span", {
                      textContent: _toDisplayString(_ctx.$t('amountToWithdraw'))
                    }, null, 8, _hoisted_4),
                    _createElementVNode("span", null, _toDisplayString(_ctx.propPercentage) + "%", 1)
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.missingPrices ? '-' : _ctx.total), 1),
                    _createVNode(_component_BalRangeInput, {
                      class: "w-1/2",
                      modelValue: _ctx.range,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.range = $event)),
                      max: 1000,
                      interval: 1,
                      min: 0,
                      tooltip: "none",
                      disabled: _ctx.loading
                    }, null, 8, ["modelValue", "disabled"])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pool.tokenAddresses, (token, i) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: token,
                    class: "py-3 last:mb-0"
                  }, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_BalAsset, {
                          address: token,
                          class: "mr-2"
                        }, null, 8, ["address"]),
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.fNum(_ctx.amounts[i], 'token')) + " " + _toDisplayString(_ctx.pool.onchain.tokens[token].symbol), 1),
                          _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('balance')) + ": " + _toDisplayString(_ctx.formatPropBalance(i)), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.amountUSD(i) === 0 ? '-' : _ctx.fNum(_ctx.amountUSD(i), 'usd')), 1),
                        (!_ctx.isStableLikePool)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.fNum(_ctx.tokenWeights[i], 'percent_lg')), 1))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]))
                }), 128))
              ])
            ], 64))
          : (_openBlock(), _createElementBlock("div", _hoisted_16, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tokenAddresses, (tokenAddress, i) => {
                return (_openBlock(), _createBlock(_component_TokenInput, {
                  key: tokenAddress,
                  amount: _ctx.amounts[i],
                  "onUpdate:amount": [($event: any) => (_ctx.amounts[i] = $event), ($event: any) => (_ctx.setSingleAsset(i, $event))],
                  address: _ctx.tokenAddresses[i],
                  "onUpdate:address": ($event: any) => (_ctx.tokenAddresses[i] = $event),
                  weight: _ctx.isStableLikePool ? 0 : _ctx.tokenWeights[i],
                  customBalance: _ctx.singleAssetMaxes[i],
                  name: tokenAddress,
                  class: "mb-4",
                  fixedToken: "",
                  onClick: ($event: any) => (_ctx.setSingleAsset(i))
                }, null, 8, ["amount", "onUpdate:amount", "address", "onUpdate:address", "weight", "customBalance", "name", "onClick"]))
              }), 128))
            ])),
        _createElementVNode("div", _hoisted_17, [
          (!_ctx.isWalletReady)
            ? (_openBlock(), _createBlock(_component_BalBtn, {
                key: 0,
                label: _ctx.$t('connectWallet'),
                block: "",
                onClick: _withModifiers(_ctx.toggleWalletSelectModal, ["prevent"])
              }, null, 8, ["label", "onClick"]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("div", {
                  class: _normalizeClass(['flex items-center text-sm mb-4', _ctx.priceImpactClasses]),
                  onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('priceImpact')) + ": " + _toDisplayString(_ctx.fNum(_ctx.priceImpact, 'percent')), 1),
                  _createVNode(_component_BalTooltip, null, {
                    activator: _withCtx(() => [
                      (_ctx.priceImpact >= 0.01)
                        ? (_openBlock(), _createBlock(_component_BalIcon, {
                            key: 0,
                            name: "alert-triangle",
                            size: "xs",
                            class: "ml-1"
                          }))
                        : (_openBlock(), _createBlock(_component_BalIcon, {
                            key: 1,
                            name: "info",
                            size: "xs",
                            class: "text-gray-400 -mb-px ml-2"
                          }))
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        innerHTML: _ctx.$t('withdrawWarning'),
                        class: "w-52"
                      }, null, 8, _hoisted_18)
                    ]),
                    _: 1
                  })
                ], 2),
                (_ctx.priceImpact >= 0.01)
                  ? (_openBlock(), _createBlock(_component_BalCheckbox, {
                      key: 0,
                      modelValue: _ctx.highPiAccepted,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.highPiAccepted = $event)),
                      rules: [_ctx.isRequired(_ctx.$t('priceImpactCheckbox'))],
                      name: "highPiAccepted",
                      class: "text-gray-500 mb-8",
                      size: "sm",
                      label: _ctx.$t('priceImpactAccept', [_ctx.$t('withdrawing')])
                    }, null, 8, ["modelValue", "rules", "label"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_BalBtn, {
                  type: "submit",
                  "loading-label": _ctx.$t('confirming'),
                  color: "gradient",
                  disabled: !_ctx.hasAmounts || _ctx.isMismatchedNetwork,
                  loading: _ctx.loading,
                  block: "",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.trackGoal(_ctx.Goals.ClickWithdraw)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('withdraw')) + " " + _toDisplayString(_ctx.missingPrices || _ctx.total.length > 15 ? '' : _ctx.total), 1)
                  ]),
                  _: 1
                }, 8, ["loading-label", "disabled", "loading"])
              ], 64))
        ])
      ]),
      _: 1
    }, 8, ["onOnSubmit"]),
    (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
      (_ctx.modalTransactionsPreviewIsOpen)
        ? (_openBlock(), _createBlock(_component_transactions_preview_modal, {
            key: 0,
            transactions: _ctx.transactions,
            onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.modalTransactionsPreviewIsOpen = false)),
            onSuccess: _cache[6] || (_cache[6] = ($event: any) => (_ctx.modalTransactionsPreviewIsOpen = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('withdrawTokensWarning')), 1)
            ]),
            _: 1
          }, 8, ["transactions"]))
        : _createCommentVNode("", true)
    ]))
  ], 64))
}