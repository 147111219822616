import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7de4b638")
const _hoisted_1 = { class: "trade-container" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_TradeCard = _resolveComponent("TradeCard")!
  const _component_TradeCardGP = _resolveComponent("TradeCardGP")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.appLoading || _ctx.loadingTokenLists)
        ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
            key: 0,
            class: "h-96"
          }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.tradeInterface === _ctx.TradeInterface.BALANCER)
              ? (_openBlock(), _createBlock(_component_TradeCard, { key: 0 }))
              : (_ctx.tradeInterface === _ctx.TradeInterface.GNOSIS)
                ? (_openBlock(), _createBlock(_component_TradeCardGP, { key: 1 }))
                : _createCommentVNode("", true)
          ], 64))
    ])
  ]))
}