
import useBreakpoints from '@/composables/useBreakpoints';
import useNumbers from '@/composables/useNumbers';
import useLiquidityRewards from '@/composables/rewards/useLiquidityRewards';
import useStakingRewardsQuery from '@/composables/queries/useStakingRewardsQuery';
import useTradingRewardsQuery from '@/composables/queries/useTradingRewardsQuery';
import useWeb3 from '@/services/web3/useWeb3';
import { computed, defineComponent } from 'vue';
import { BigNumber } from '@ethersproject/bignumber';

export default defineComponent({
  name: 'AppNavActivityBtn',

  components: {},

  setup() {
    /**
     * COMPOSABLES
     */
    const { upToLargeBreakpoint } = useBreakpoints();
    const { isLoadingProfile, profile, account } = useWeb3();
    const { fNumToken } = useNumbers();

    const { total, claimable } = useLiquidityRewards();
    const { data: stakingRewardsData } = useStakingRewardsQuery();
    const { data: tradingRewardsData } = useTradingRewardsQuery();

    const claimableRewardsUSDC = computed(() =>
      BigNumber.from(0)
        .add(stakingRewardsData.value?.locked.vestedRewards ?? 0)
        .add(stakingRewardsData.value?.unlocked.vestedRewards ?? 0)
    );
    const totalRewardsUSDC = computed(() =>
      BigNumber.from(0)
        .add(stakingRewardsData.value?.locked.totalRewards ?? 0)
        .add(stakingRewardsData.value?.unlocked.totalRewards ?? 0)
    );

    const totalRewardsHalt = computed(() =>
      total.value.add(tradingRewardsData.value?.claimableRewards ?? 0)
    );

    const claimableRewardsHalt = computed(() =>
      claimable.value.add(tradingRewardsData.value?.totalRewards ?? 0)
    );

    return {
      totalRewardsHalt,
      claimableRewardsHalt,
      totalRewardsUSDC,
      claimableRewardsUSDC,
      account,
      profile,
      upToLargeBreakpoint,
      isLoadingProfile,
      fNumToken
    };
  }
});
