import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "relative overflow-hidden" }
const _hoisted_2 = { class: "flex justify-between items-end border-b dark:border-gray-900 px-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalTabs = _resolveComponent("BalTabs")!
  const _component_TradeSettingsPopover = _resolveComponent("TradeSettingsPopover")!
  const _component_InvestForm = _resolveComponent("InvestForm")!
  const _component_SuccessOverlay = _resolveComponent("SuccessOverlay")!
  const _component_WithdrawForm = _resolveComponent("WithdrawForm")!
  const _component_BalCard = _resolveComponent("BalCard")!
  const _component_PoolRewardsCard = _resolveComponent("PoolRewardsCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BalCard, { noPad: "" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_BalTabs, {
              modelValue: _ctx.activeTab,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeTab = $event)),
              tabs: _ctx.tabs,
              class: "pt-4 -mb-px",
              "no-pad": ""
            }, null, 8, ["modelValue", "tabs"]),
            _createVNode(_component_TradeSettingsPopover, {
              context: _ctx.TradeSettingsContext.invest
            }, null, 8, ["context"])
          ]),
          (_ctx.activeTab === 'invest')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_InvestForm, {
                  pool: _ctx.pool,
                  "missing-prices": _ctx.missingPrices,
                  onSuccess: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleInvestment($event)))
                }, null, 8, ["pool", "missing-prices"]),
                (_ctx.investmentSuccess)
                  ? (_openBlock(), _createBlock(_component_SuccessOverlay, {
                      key: 0,
                      title: _ctx.$t('investmentSettled'),
                      description: _ctx.$t('investmentSuccess'),
                      closeLabel: _ctx.$t('close'),
                      explorerLink: _ctx.explorer.txLink(_ctx.txHash),
                      onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.investmentSuccess = false))
                    }, null, 8, ["title", "description", "closeLabel", "explorerLink"]))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.activeTab === 'withdraw')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode(_component_WithdrawForm, {
                  pool: _ctx.pool,
                  "missing-prices": _ctx.missingPrices,
                  onSuccess: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleWithdrawal($event)))
                }, null, 8, ["pool", "missing-prices"]),
                (_ctx.withdrawalSuccess)
                  ? (_openBlock(), _createBlock(_component_SuccessOverlay, {
                      key: 0,
                      title: _ctx.$t('withdrawalSettled'),
                      description: _ctx.$t('withdrawalSuccess'),
                      closeLabel: _ctx.$t('close'),
                      explorerLink: _ctx.explorer.txLink(_ctx.txHash),
                      onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.withdrawalSuccess = false))
                    }, null, 8, ["title", "description", "closeLabel", "explorerLink"]))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }),
    (_ctx.shouldShowRewardsBlock)
      ? (_openBlock(), _createBlock(_component_PoolRewardsCard, {
          key: 0,
          pool: _ctx.pool,
          class: "mt-8"
        }, null, 8, ["pool"]))
      : _createCommentVNode("", true)
  ], 64))
}