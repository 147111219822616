import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-xs text-gray-500"
}
const _hoisted_2 = { class: "w-52" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalTooltip = _resolveComponent("BalTooltip")!
  const _component_BalRadio = _resolveComponent("BalRadio")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['flex flex-col px-4 py-2', _ctx.hasZeroBalance ? 'hidden' : ''])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formTypes, (type, i) => {
      return (_openBlock(), _createBlock(_component_BalRadio, {
        key: i,
        modelValue: _ctx.selected,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.selected = $event)),
        value: type.value,
        name: "formType",
        class: "py-2",
        disabled: _ctx.loading
      }, {
        label: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(type.label), 1),
          (!_ctx.missingPrices)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, " (" + _toDisplayString(type.max) + " " + _toDisplayString(_ctx.$t('max').toLowerCase()) + ") ", 1))
            : _createCommentVNode("", true),
          (type.tooltip)
            ? (_openBlock(), _createBlock(_component_BalTooltip, { key: 1 }, {
                activator: _withCtx(() => [
                  _createVNode(_component_BalIcon, {
                    name: "info",
                    size: "xs",
                    class: "text-gray-400 -mb-px ml-2"
                  })
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, _toDisplayString(type.tooltip), 1)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1032, ["modelValue", "value", "disabled"]))
    }), 128))
  ], 2))
}