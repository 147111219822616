
import { defineComponent, PropType, computed } from 'vue';
import useNumbers from '@/composables/useNumbers';
import useTokens from '@/composables/useTokens';
import { DecoratedPool } from '@/services/balancer/subgraph/types';
import { bnum } from '@/lib/utils';
import { isWstETH } from '@/composables/usePool';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'LiquidityMiningTooltip',

  props: {
    pool: {
      type: Object as PropType<DecoratedPool>,
      required: true
    }
  },

  setup(props) {
    /**
     * COMPOSABLES
     */
    const { fNum } = useNumbers();
    const { getTokens } = useTokens();
    const { t } = useI18n();

    /**
     * COMPUTED
     */
    const lmBreakdown = computed(
      () => props.pool.dynamic.apr.liquidityMiningBreakdown
    );

    const lmTokens = computed(() => getTokens(Object.keys(lmBreakdown.value)));

    const multiRewardPool = computed(
      () => Object.keys(lmTokens.value).length > 1
    );

    const hasThirdPartyAPR = computed(() =>
      bnum(props.pool.dynamic.apr.thirdParty).gt(0)
    );

    const thirdPartyAPRLabel = computed(() => {
      if (isWstETH(props.pool)) return t('thirdPartyAPR.steth');
      return '';
    });

    return {
      lmBreakdown,
      fNum,
      lmTokens,
      multiRewardPool,
      hasThirdPartyAPR,
      thirdPartyAPRLabel
    };
  }
});
