import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "lg:container lg:mx-auto pt-10 md:pt-12 pb-8 md:pb-0" }
const _hoisted_2 = { class: "px-4 md:px-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalTabs = _resolveComponent("BalTabs")!
  const _component_liquidity_rewards = _resolveComponent("liquidity-rewards")!
  const _component_trading_rewards = _resolveComponent("trading-rewards")!
  const _component_staking_rewards = _resolveComponent("staking-rewards")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('manageYourRewards')), 1),
    _createVNode(_component_BalTabs, {
      modelValue: _ctx.activeTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeTab = $event)),
      tabs: _ctx.tabs,
      class: "pt-4 mb-6 px-4 md:px-0 text-center w-full",
      "no-pad": ""
    }, null, 8, ["modelValue", "tabs"]),
    (_ctx.activeTab === 'liquidity')
      ? (_openBlock(), _createBlock(_component_liquidity_rewards, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.activeTab === 'trading')
      ? (_openBlock(), _createBlock(_component_trading_rewards, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.activeTab === 'staking')
      ? (_openBlock(), _createBlock(_component_staking_rewards, { key: 2 }))
      : _createCommentVNode("", true)
  ]))
}