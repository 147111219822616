import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "hidden lg:block"
}
const _hoisted_2 = {
  key: 0,
  class: "divide-y dark:divide-gray-900 w-72"
}
const _hoisted_3 = { class: "p-3" }
const _hoisted_4 = { class: "text-lg mb-3" }
const _hoisted_5 = {
  key: 0,
  class: "text-sm text-gray-600 mb-1"
}
const _hoisted_6 = {
  key: 2,
  class: "text-sm text-gray-600 mb-1"
}
const _hoisted_7 = {
  key: 3,
  class: "flex justify-between items-center mb-2"
}
const _hoisted_8 = { class: "text-lg font-bold" }
const _hoisted_9 = {
  key: 0,
  class: "p-3"
}
const _hoisted_10 = { class: "text-sm text-gray-600 mb-1" }
const _hoisted_11 = { class: "flex justify-between items-center mb-2" }
const _hoisted_12 = { class: "text-lg font-bold" }
const _hoisted_13 = {
  key: 1,
  class: "p-3 text-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StarsIcon = _resolveComponent("StarsIcon")!
  const _component_BalLoadingIcon = _resolveComponent("BalLoadingIcon")!
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_BalAlert = _resolveComponent("BalAlert")!
  const _component_BalPopover = _resolveComponent("BalPopover")!

  return (_openBlock(), _createBlock(_component_BalPopover, { "no-pad": "" }, {
    activator: _withCtx(() => [
      _createVNode(_component_BalBtn, {
        color: "white",
        class: "mr-2 text-base",
        size: _ctx.upToLargeBreakpoint ? 'md' : 'sm',
        circle: _ctx.upToLargeBreakpoint
      }, {
        default: _withCtx(() => [
          (_ctx.upToLargeBreakpoint ? !_ctx.userClaimsLoading : true)
            ? (_openBlock(), _createBlock(_component_StarsIcon, {
                key: 0,
                class: _normalizeClass({ 'mr-2': !_ctx.upToLargeBreakpoint })
              }, null, 8, ["class"]))
            : _createCommentVNode("", true),
          (_ctx.userClaimsLoading)
            ? (_openBlock(), _createBlock(_component_BalLoadingIcon, {
                key: 1,
                size: "sm"
              }))
            : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.fNum(_ctx.totalRewards, _ctx.totalRewards > 0 ? 'token_fixed' : 'token')), 1))
        ]),
        _: 1
      }, 8, ["size", "circle"])
    ]),
    default: _withCtx(() => [
      (_ctx.userClaims != null)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.$t('liquidityMining')), 1),
              (_ctx.isPolygon)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('airdropExplainer')), 1))
                : _createCommentVNode("", true),
              (_ctx.shouldShowClaimFreezeWarning & !_ctx.isPolygon)
                ? (_openBlock(), _createBlock(_component_BalAlert, {
                    key: 1,
                    title: "Too many claims",
                    description: _ctx.$t('claimFreezeWarning'),
                    type: "warning",
                    size: "sm",
                    class: "mb-3"
                  }, null, 8, ["description"]))
                : _createCommentVNode("", true),
              (!_ctx.isPolygon)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('availableToClaim')) + ": ", 1))
                : _createCommentVNode("", true),
              (!_ctx.isPolygon)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.fNum(
                _ctx.userClaims.availableToClaim,
                _ctx.userClaims.availableToClaim > 0 ? 'token_fixed' : 'token'
              )) + " BAL ", 1),
                    _createElementVNode("div", null, _toDisplayString(_ctx.availableToClaimInUSD != null
                ? _ctx.fNum(_ctx.availableToClaimInUSD, 'usd')
                : '-'), 1)
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.isPolygon)
                ? (_openBlock(), _createBlock(_component_BalBtn, {
                    key: 4,
                    color: "gradient",
                    size: "md",
                    block: "",
                    class: "mb-1 ",
                    loading: _ctx.isClaiming,
                    "loading-label": _ctx.$t('claiming'),
                    onClick: _ctx.claimAvailableRewards,
                    disabled: _ctx.userClaims.availableToClaim == 0
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('claim')) + " BAL", 1)
                    ]),
                    _: 1
                  }, 8, ["loading", "loading-label", "onClick", "disabled"]))
                : _createCommentVNode("", true)
            ]),
            (_ctx.currentRewards != null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('pendingEstimate')), 1),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.fNum(_ctx.currentRewards, _ctx.currentRewards > 0 ? 'token_fixed' : 'token')) + " BAL ", 1),
                    _createElementVNode("div", null, _toDisplayString(_ctx.currentRewardsInUSD != null
                ? _ctx.fNum(_ctx.currentRewardsInUSD, 'usd')
                : '-'), 1)
                  ])
                ]))
              : (_ctx.totalRewards == 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.$t('liquidityProviderCopy')), 1))
                : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}