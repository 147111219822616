import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "text-sm font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('linearVestingDisclaimerTitle')), 1),
    _createElementVNode("div", null, _toDisplayString(_ctx.$t('linearVestingDisclaimerBody')), 1),
    _createVNode(_component_BalBtn, {
      color: "gradient",
      class: "mt-2 w-full",
      onClick: _withModifiers(_ctx.earnRewards, ["prevent"]),
      loading: _ctx.isLoading
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('earnRewards')), 1)
      ]),
      _: 1
    }, 8, ["onClick", "loading"])
  ]))
}