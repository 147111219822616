
import LiquidityRewards from '@/components/pages/manage-rewards/LiquidityRewards.vue';
import StakingRewards from '@/components/pages/manage-rewards/StakingRewards.vue';
import TradingRewards from '@/components/pages/manage-rewards/TradingRewards.vue';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    LiquidityRewards,
    StakingRewards,
    TradingRewards
  },

  setup() {
    const { t } = useI18n();

    const tabs = [
      { value: 'liquidity', label: t('liquidityMining') },
      { value: 'trading', label: t('tradingRewards') },
      { value: 'staking', label: t('stakingRewards') }
    ];
    const activeTab = ref(tabs[0].value);

    return { tabs, activeTab };
  }
});
