import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full max-w-2xl mx-auto" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = {
  key: 1,
  class: "text-3xl font-bold text-white"
}
const _hoisted_4 = ["textContent"]
const _hoisted_5 = { class: "flex justify-center mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_BalIcon = _resolveComponent("BalIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['app-hero', _ctx.classes])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isWalletReady)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("h1", {
              textContent: _toDisplayString(_ctx.$t('myInvestments')),
              class: "text-base font-medium text-white opacity-90 font-body mb-2"
            }, null, 8, _hoisted_2),
            (_ctx.isLoadingUserPools)
              ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
                  key: 0,
                  class: "h-10 w-40 mx-auto",
                  white: ""
                }))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.fNum(_ctx.totalInvestedAmount, 'usd', { forcePreset: true })), 1))
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("h1", {
              textContent: _toDisplayString(_ctx.$t('ammPlatform')),
              class: "text-white text-center text-4xl md:text-5xl pb-2"
            }, null, 8, _hoisted_4),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_BalBtn, {
                color: _ctx.darkMode ? 'gray' : 'white',
                class: "mr-3",
                onClick: _ctx.onClickConnect
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('connectWallet')), 1)
                ]),
                _: 1
              }, 8, ["color", "onClick"]),
              _createVNode(_component_BalBtn, {
                tag: "a",
                href: _ctx.EXTERNAL_LINKS.Balancer.Home,
                target: "_blank",
                rel: "noreferrer",
                color: "white",
                outline: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.trackGoal(_ctx.Goals.ClickHeroLearnMore)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('learnMore')) + " ", 1),
                  _createVNode(_component_BalIcon, {
                    name: "arrow-up-right",
                    size: "sm",
                    class: "ml-1"
                  })
                ]),
                _: 1
              }, 8, ["href"])
            ])
          ], 64))
    ])
  ], 2))
}