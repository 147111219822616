import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-935b48a4")
const _hoisted_1 = {
  key: 0,
  class: "message relative px-2 py-3"
}
const _hoisted_2 = { class: "ml-12" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["innerHTML"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalLink = _resolveComponent("BalLink")!

  return (_openBlock(), _createBlock(_component_BalLink, {
    href: _ctx.EXTERNAL_LINKS.Balancer.BalForGas,
    external: "",
    class: "block hover:no-underline"
  }, {
    default: _withCtx(() => [
      (_ctx.isActive())
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h6", {
                textContent: _toDisplayString(_ctx.$t('highGasFees')),
                class: "relative text-sm"
              }, null, 8, _hoisted_3),
              _createElementVNode("div", {
                class: "relative text-sm text-gray-500",
                innerHTML: _ctx.text
              }, null, 8, _hoisted_4)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["href"]))
}