import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "px-6 py-2" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "flex center mr-3" }
const _hoisted_4 = { class: "px-6 py-4 flex -mt-1 flex-wrap" }
const _hoisted_5 = {
  key: 0,
  class: "m-1 flex items-center p-1 px-2 bg-gray-50 dark:bg-gray-700 rounded-lg"
}
const _hoisted_6 = { class: "font-numeric" }
const _hoisted_7 = { class: "px-6 py-4 flex justify-end font-numeric" }
const _hoisted_8 = { class: "px-6 py-4" }
const _hoisted_9 = { class: "flex items-center justify-end wrap whitespace-nowrap text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalAsset = _resolveComponent("BalAsset")!
  const _component_BalLink = _resolveComponent("BalLink")!
  const _component_BalTable = _resolveComponent("BalTable")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, {
    class: "overflow-x-auto",
    square: $setup.upToLargeBreakpoint,
    noBorder: $setup.upToLargeBreakpoint,
    noPad: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BalTable, {
        columns: $setup.columns,
        data: $setup.activityRows,
        "is-loading": $props.isLoading,
        "is-loading-more": $props.isLoadingMore,
        "is-paginated": $props.isPaginated,
        onLoadMore: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('loadMore'))),
        "skeleton-class": "h-64",
        sticky: "both",
        "no-results-label": $props.noResultsLabel,
        "initial-state": {
        sortColumn: 'timeAgo',
        sortDirection: 'desc'
      }
      }, {
        actionCell: _withCtx((action) => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (action.type === 'Join')
                  ? (_openBlock(), _createBlock(_component_BalIcon, {
                      key: 0,
                      name: "plus",
                      size: "sm",
                      class: "text-green-500 dark:text-green-400"
                    }))
                  : (_openBlock(), _createBlock(_component_BalIcon, {
                      key: 1,
                      name: "minus",
                      size: "sm",
                      class: "text-red-500"
                    }))
              ]),
              _createElementVNode("div", null, _toDisplayString(action.label), 1)
            ])
          ])
        ]),
        detailsCell: _withCtx((action) => [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(action.tokenAmounts, (tokenAmount, i) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                (tokenAmount.amount !== '0')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_BalAsset, {
                        address: tokenAmount.address,
                        class: "mr-2 flex-shrink-0"
                      }, null, 8, ["address"]),
                      _createElementVNode("span", _hoisted_6, _toDisplayString(tokenAmount.amount), 1)
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ])
        ]),
        valueCell: _withCtx((action) => [
          _createElementVNode("div", _hoisted_7, _toDisplayString($setup.fNum(action.value, 'usd_m')), 1)
        ]),
        timeCell: _withCtx((action) => [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createTextVNode(_toDisplayString(action.formattedDate) + " ", 1),
              _createVNode(_component_BalLink, {
                href: $setup.explorer.txLink(action.tx),
                external: "",
                class: "ml-2 flex items-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BalIcon, {
                    name: "arrow-up-right",
                    size: "sm",
                    class: "text-gray-500 hover:text-blue-500 transition-colors"
                  })
                ]),
                _: 2
              }, 1032, ["href"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["columns", "data", "is-loading", "is-loading-more", "is-paginated", "no-results-label"])
    ]),
    _: 1
  }, 8, ["square", "noBorder"]))
}