import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-5c239e68")
const _hoisted_1 = {
  key: 0,
  class: "-mx-4 mb-4 p-4 flex items-center border-b border-t dark:border-gray-800"
}
const _hoisted_2 = { class: "mb-3 text-sm" }
const _hoisted_3 = { class: "card-step text-green-500" }
const _hoisted_4 = {
  key: 1,
  class: "text-gray-500 dark:text-gray-400"
}
const _hoisted_5 = { class: "ml-3" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_BalModal = _resolveComponent("BalModal")!

  return (_openBlock(), _createBlock(_component_BalModal, {
    show: "",
    onClose: _ctx.onClose,
    title: _ctx.$t('previewTransactions')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        (_ctx.$slots.default)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, " Requires " + _toDisplayString(_ctx.transactionList.length) + " transactions ", 1),
          _createElementVNode("div", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.transactionList, (transaction, transactionIndex) => {
              return (_openBlock(), _createElementBlock("div", {
                key: transaction.title,
                class: "mb-3 card-container"
              }, [
                _createElementVNode("div", _hoisted_3, [
                  (transactionIndex < _ctx.currentTransaction)
                    ? (_openBlock(), _createBlock(_component_BalIcon, {
                        key: 0,
                        name: "check",
                        class: "text-green-500"
                      }))
                    : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(transactionIndex + 1), 1))
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", null, _toDisplayString(transaction.title), 1)
                ])
              ]))
            }), 128))
          ])
        ]),
        _createVNode(_component_BalBtn, {
          class: "mt-5",
          label: _ctx.$t('confirm'),
          loading: _ctx.isExecuting,
          "loading-label": _ctx.$t('confirming'),
          color: "gradient",
          block: "",
          onClick: _withModifiers(_ctx.confirm, ["prevent"])
        }, null, 8, ["label", "loading", "loading-label", "onClick"])
      ])
    ]),
    _: 3
  }, 8, ["onClose", "title"]))
}