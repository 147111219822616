import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, Teleport as _Teleport, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-147ca3de")
const _hoisted_1 = { class: "w-8" }
const _hoisted_2 = { class: "text-base font-medium" }
const _hoisted_3 = {
  key: 0,
  class: "text-gray-500 ml-2"
}
_popScopeId()

import { ref, computed } from 'vue';
import SelectTokenModal from '@/components/modals/SelectTokenModal/SelectTokenModal.vue';
import useTokens from '@/composables/useTokens';
import useNumbers from '@/composables/useNumbers';
import { TokenInfo } from '@/types/TokenList';

/**
 * TYPES
 */
type Props = {
  modelValue: string;
  fixed?: boolean;
  weight?: number;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    modelValue: { type: String, required: true, default: '' },
    fixed: { type: Boolean, required: false, default: false },
    weight: { type: Number, required: false, default: 0 }
  } as unknown as undefined,
  emits: ["update:modelValue"] as unknown as undefined,
  setup(__props: {
  modelValue: string;
  fixed?: boolean;
  weight?: number;
}, { emit }: { emit: ({
  (e: 'update:modelValue', value: string): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props




/**
 * STATE
 */
const openTokenModal = ref(false);

/**
 * COMPOSABLEs
 */
const { getToken } = useTokens();
const { fNum } = useNumbers();

/**
 * COMPUTED
 */
const hasToken = computed(() => !!props.modelValue);

const token = computed((): TokenInfo | null => {
  if (!hasToken.value) return null;
  return getToken(props.modelValue);
});

/**
 * METHODS
 */
const toggleModal = () => {
  if (!props.fixed) openTokenModal.value = !openTokenModal.value;
};

return (_ctx: any,_cache: any) => {
  const _component_BalAsset = _resolveComponent("BalAsset")!
  const _component_BalIcon = _resolveComponent("BalIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(hasToken))
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(['token-select-input selected group', { selectable: !__props.fixed }]),
          onClick: toggleModal
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_BalAsset, {
              address: _unref(token)?.address,
              class: "shadow"
            }, null, 8, ["address"])
          ]),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(token)?.symbol), 1),
          (__props.weight > 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_unref(fNum)(__props.weight, 'percent_lg')), 1))
            : _createCommentVNode("", true),
          (!__props.fixed)
            ? (_openBlock(), _createBlock(_component_BalIcon, {
                key: 1,
                name: "chevron-down",
                size: "sm",
                class: "text-blue-500 group-hover:text-pink-500 ml-2"
              }))
            : _createCommentVNode("", true)
        ], 2))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "token-select-input unselected selectable",
          onClick: toggleModal
        }, [
          _createTextVNode(_toDisplayString(_ctx.$t('selectToken')) + " ", 1),
          _createVNode(_component_BalIcon, {
            name: "chevron-down",
            size: "sm",
            class: "ml-2"
          })
        ])),
    (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
      (openTokenModal.value)
        ? (_openBlock(), _createBlock(SelectTokenModal, {
            key: 0,
            excludedTokens: [__props.modelValue],
            includeEther: true,
            onClose: _cache[0] || (_cache[0] = ($event: any) => (openTokenModal.value = false)),
            onSelect: _cache[1] || (_cache[1] = ($event: any) => (emit('update:modelValue', $event)))
          }, null, 8, ["excludedTokens"]))
        : _createCommentVNode("", true)
    ]))
  ]))
}
}

})