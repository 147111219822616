
import useFathom from '@/composables/useFathom';
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'AppNavToggle',

  props: {
    darkModeBg: { type: String, default: '800' }
  },

  setup() {
    const route = useRoute();
    const activeClasses = 'gradient-purple-diagonal text-white';
    const currentPage = computed(() => route.name);
    const { trackGoal, Goals } = useFathom();

    return {
      currentPage,
      activeClasses,
      trackGoal,
      Goals
    };
  }
});
