
import TokenInput from '@/components/inputs/TokenInput/TokenInput.vue';
import useStackingRewardsQuery from '@/composables/queries/useStakingRewardsQuery';
import useStakingRewardsContracts from '@/composables/rewards/useStakingRewardsContract';
import useEthers from '@/composables/useEthers';
import useRewardsWeek from '@/composables/useRewardsWeek';
import useSimpleTokenApproval from '@/composables/useSimpleTokenApproval';
import useTokens from '@/composables/useTokens';
import useTransactions from '@/composables/useTransactions';
import { configService } from '@/services/config/config.service';
import useWeb3 from '@/services/web3/useWeb3';
import { BigNumber } from '@ethersproject/bignumber';
import { ContractTransaction } from '@ethersproject/contracts';
import { formatUnits, parseEther } from '@ethersproject/units';
import { formatDistanceToNow } from 'date-fns';
import { computed, defineComponent, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    TokenInput
  },

  setup() {
    const formatBn = (bn: BigNumber) => formatUnits(bn);

    const { t } = useI18n();
    const { account } = useWeb3();
    const { getToken, balanceFor } = useTokens();

    const {
      data: stakingRewardsData,
      refetch: refetchStakingRewards
    } = useStackingRewardsQuery();
    const [unlockedContract, lockedContract] = useStakingRewardsContracts();
    const { currentWeek } = useRewardsWeek();
    const { addTransaction } = useTransactions();
    const { txListener } = useEthers();
    const halter = computed(() =>
      getToken(configService.network.addresses.stakeToken)
    );
    const tokenBalance = computed(() =>
      parseEther(balanceFor(configService.network.addresses.stakeToken))
    );
    const depositType = ref<'locked' | 'unlocked'>('unlocked');
    const selectedContract = computed(() =>
      depositType.value === 'locked'
        ? lockedContract.value.address
        : unlockedContract.value.address
    );
    const { requiresApproval, approveAllowance } = useSimpleTokenApproval(
      halter,
      account,
      tokenBalance,
      selectedContract
    );

    const staked = computed(() => BigNumber.from(0));

    const normalizedPurgatory = computed(
      () =>
        stakingRewardsData.value?.locked.withdrawPurgatory?.map(
          ([amount, unlockTime]) => {
            return {
              amount,
              unlockTime: new Date(unlockTime.toNumber() * 1000)
            };
          }
        ) ?? []
    );

    const unlocked = computed(() =>
      normalizedPurgatory.value
        .filter(item => item.unlockTime.getTime() < Date.now())
        .reduce(
          (acc: BigNumber, { amount }) => acc.add(amount),
          BigNumber.from(0)
        )
    );

    const available = computed(() => BigNumber.from(0));

    const availableForDepositType = computed(() =>
      depositType.value === 'unlocked'
        ? stakingRewardsData.value?.unlocked.stakedAmount
        : unlocked.value ?? BigNumber.from(0)
    );

    const locked = computed(
      () => stakingRewardsData.value?.locked.stakedAmount ?? BigNumber.from(0)
    );

    const rewards = computed(() => BigNumber.from(0));

    const tabs = [
      { value: 'deposit', label: t('deposit') },
      { value: 'unlock', label: t('unlock') },
      { value: 'withdraw', label: t('withdraw') }
    ];

    const unlockingColumns = computed(() => [
      {
        name: t('amount'),
        id: 'amount',
        accessor: item => `${formatBn(item.amount)} HALT`
      },
      {
        name: t('timeToUnlock'),
        id: 'timeToUnlock',
        accessor: item => formatDistanceToNow(item.unlockTime),
        align: 'right'
      }
    ]);

    const activeTab = ref(tabs[0].value);

    const isDepositInputValid = ref(true);
    const isUnlockInputValid = ref(true);
    const isWithdrawInputValid = ref(true);
    const amountToStake = ref('0');
    const amountToUnlock = ref('0');
    const amountToWithdraw = ref('0');

    watchEffect(() => {
      amountToStake.value = balanceFor(halter.value?.address);
      amountToUnlock.value = formatUnits(locked.value);
    }, {});

    async function deposit() {
      if (requiresApproval.value) {
        await approveAllowance();
        return;
      }

      const contract =
        depositType.value === 'locked' ? lockedContract : unlockedContract;

      const tx = await contract.value.stake(
        parseEther(amountToStake.value),
        currentWeek.value
      );

      addTransaction({
        id: tx.hash,
        type: 'tx',
        action: 'deposit',
        summary: t('transactionSummary.depositStaking'),
        details: {
          contractAddress: contract.value.address
        }
      });

      txListener(tx, {
        onTxConfirmed: async () => {
          refetchStakingRewards.value();
        }
      });
    }

    async function unlock() {
      const tx = await lockedContract.value.startWithdrawLock(
        parseEther(amountToUnlock.value),
        currentWeek.value
      );
      addTransaction({
        id: tx.hash,
        type: 'tx',
        action: 'unlock',
        summary: t('transactionSummary.unlockingStaking'),
        details: {
          contractAddress: lockedContract.value.address
        }
      });

      txListener(tx, {
        onTxConfirmed: async () => {
          refetchStakingRewards.value();
        }
      });
    }

    async function withdraw() {
      let tx: ContractTransaction;
      if (depositType.value === 'locked') {
        tx = await lockedContract.value.withdrawUnlockedTokens();
      } else {
        const parsedValue = parseEther(amountToWithdraw.value);
        if (parsedValue.isZero()) {
          return;
        }

        tx = await unlockedContract.value.withdraw(
          parsedValue,
          currentWeek.value
        );
      }

      addTransaction({
        id: tx.hash,
        type: 'tx',
        action: 'withdraw',
        summary: t('transactionSummary.withdraw'),
        details: {
          // contractAddress: contract.value.address
        }
      });

      txListener(tx, {
        onTxConfirmed: async () => {
          refetchStakingRewards.value();
        }
      });
    }

    return {
      activeTab,
      tabs,
      unlockingColumns,
      normalizedPurgatory,
      formatBn,
      halter,
      staked,
      available,
      availableForDepositType,
      unlocked,
      locked,
      rewards,
      requiresApproval,
      isDepositInputValid,
      isUnlockInputValid,
      isWithdrawInputValid,
      amountToStake,
      amountToUnlock,
      amountToWithdraw,
      depositType,
      deposit,
      unlock,
      withdraw
    };
  }
});
