
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

import useBreakpoints from '@/composables/useBreakpoints';
import useTransactions from '@/composables/useTransactions';
import useNotifications from '@/composables/useNotifications';

import useWeb3 from '@/services/web3/useWeb3';
import { signOrderCancellation } from '@/services/gnosis/signing';
import { gnosisProtocolService } from '@/services/gnosis/gnosisProtocol.service';

import ActivityCounter from './ActivityCounter.vue';
import ActivityRows from './ActivityRows.vue';

export default defineComponent({
  name: 'AppNavActivityBtn',

  components: {
    ActivityCounter,
    ActivityRows
  },

  setup() {
    /**
     * COMPOSABLES
     */
    const { upToLargeBreakpoint } = useBreakpoints();
    const { isLoadingProfile, profile, account, getSigner } = useWeb3();
    const { t } = useI18n();

    const {
      transactions,
      pendingTransactions,
      finalizedTransactions,
      getExplorerLink,
      clearAllTransactions,
      isSuccessfulTransaction,
      updateTransaction,
      isPendingTransactionStatus
    } = useTransactions();

    const { addNotification } = useNotifications();

    /**
     * METHODS
     */
    async function cancelOrder(orderId: string) {
      try {
        const { signature, signingScheme } = await signOrderCancellation(
          orderId,
          getSigner()
        );

        await gnosisProtocolService.sendSignedOrderCancellation({
          cancellation: {
            orderUid: orderId,
            signature,
            signingScheme
          },
          owner: account.value
        });

        updateTransaction(orderId, 'order', {
          status: 'cancelling'
        });
      } catch (e) {
        console.log(e);
        addNotification({
          type: 'error',
          title: t('errorCancellingOrder'),
          message: e.message
        });
      }
    }

    return {
      // methods
      clearAllTransactions,
      getExplorerLink,
      isSuccessfulTransaction,
      isPendingTransactionStatus,
      cancelOrder,

      // computed
      account,
      profile,
      upToLargeBreakpoint,
      isLoadingProfile,
      transactions,
      pendingTransactions,
      finalizedTransactions
    };
  }
});
