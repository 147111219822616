import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createBlock(_component_Table, {
    tokens: _ctx.pool ? _ctx.pool.tokensList : [],
    "pool-activities": _ctx.poolActivities,
    "is-loading": _ctx.loading || _ctx.isLoadingPoolActivities,
    "is-loading-more": _ctx.poolActivitiesIsFetchingNextPage,
    "is-paginated": _ctx.poolActivitiesHasNextPage,
    onLoadMore: _ctx.loadMorePoolActivities,
    "no-results-label": 
      _ctx.poolActivityType === _ctx.PoolActivityTab.ALL_ACTIVITY
        ? _ctx.$t('noTransactionsPool')
        : _ctx.$t('noTransactionsUserPool')
    
  }, null, 8, ["tokens", "pool-activities", "is-loading", "is-loading-more", "is-paginated", "onLoadMore", "no-results-label"]))
}