
import PoolsTable from '@/components/tables/PoolsTable/PoolsTable.vue';
import useUserRewardPoolsQuery from '@/composables/queries/useUserRewardPoolsQuery';
import { defineComponent, computed } from 'vue';
import useLiquidityRewards from '@/composables/rewards/useLiquidityRewards';
import BigNumber from 'bignumber.js';
import useNumbers from '@/composables/useNumbers';

export default defineComponent({
  name: 'LiquidityRewards',
  components: { PoolsTable },

  setup() {
    const {
      data: userRewardPoolsData,
      isLoading: isLoadingUserRewardPools
    } = useUserRewardPoolsQuery();
    const { total, claimable } = useLiquidityRewards();
    const { fNumToken } = useNumbers();

    const userRewardPools = computed(() => userRewardPoolsData.value ?? []);

    const lockedRewards = computed(() => total.value.sub(claimable.value));

    const completedSteps = computed(() => {
      if (total.value.isZero()) {
        return 0;
      }

      const claimableBn = new BigNumber(claimable.value.toString());
      const totalBn = new BigNumber(total.value.toString());
      const ratio = claimableBn.dividedBy(totalBn).times(100);

      return ratio.gt(1) ? ratio.toNumber() : 0;
    });

    const formatToken = (amount: string) => fNumToken(amount);

    const gradient = {
      radial: false,
      colors: [
        {
          color: '#D742FF',
          offset: '0',
          opacity: '1'
        },

        {
          color: '#1B52EB',
          offset: '70',
          opacity: '1'
        },
        {
          color: '#D742FF',
          offset: '100',
          opacity: '1'
        }
      ]
    };

    return {
      formatToken,
      userRewardPools,
      isLoadingUserRewardPools,
      claimableRewards: claimable,
      totalRewards: total,
      lockedRewards,
      completedSteps,
      gradient
    };
  }
});
