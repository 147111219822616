
import {
  defineComponent,
  computed,
  watch,
  onMounted,
  reactive,
  toRefs,
  ref,
  PropType,
  toRef
} from 'vue';
import { FormRef } from '@/types';
import {
  isPositive,
  isLessThanOrEqualTo,
  isRequired
} from '@/lib/utils/validations';
import { useI18n } from 'vue-i18n';
import { formatUnits } from '@ethersproject/units';
import isEqual from 'lodash/isEqual';

import useTokenApprovals from '@/composables/pools/useTokenApprovals';
import useNumbers from '@/composables/useNumbers';
import useSlippage from '@/composables/useSlippage';

import PoolExchange from '@/services/pool/exchange';
import PoolCalculator from '@/services/pool/calculator/calculator.sevice';
import { getPoolWeights } from '@/services/pool/pool.helper';
import { bnum } from '@/lib/utils';
import FormTypeToggle from './shared/FormTypeToggle.vue';
import { FullPool } from '@/services/balancer/subgraph/types';
import useFathom from '@/composables/useFathom';

import { TOKENS } from '@/constants/tokens';
import useWeb3 from '@/services/web3/useWeb3';
import useTokens from '@/composables/useTokens';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import useEthers from '@/composables/useEthers';
import useTransactions from '@/composables/useTransactions';
import { usePool } from '@/composables/usePool';
import TokenInput from '@/components/inputs/TokenInput/TokenInput.vue';

export enum FormTypes {
  proportional = 'proportional',
  custom = 'custom'
}

type DataProps = {
  investForm: FormRef;
  investType: FormTypes;
  loading: boolean;
  tokenAddresses: string[];
  amounts: string[];
  propMax: string[];
  validInputs: boolean[];
  propToken: number;
  range: number;
  highPiAccepted: boolean;
};

export default defineComponent({
  name: 'InvestForm',

  components: {
    FormTypeToggle,
    TokenInput
  },

  emits: ['success'],

  props: {
    pool: { type: Object as PropType<FullPool>, required: true },
    missingPrices: { type: Boolean, default: false }
  },

  setup(props: { pool: FullPool }, { emit }) {
    const data = reactive<DataProps>({
      investForm: {} as FormRef,
      investType: FormTypes.proportional,
      loading: false,
      tokenAddresses: props.pool.tokenAddresses,
      amounts: [],
      propMax: [],
      validInputs: [],
      propToken: 0,
      range: 1000,
      highPiAccepted: false
    });

    // COMPOSABLES
    const {
      isWalletReady,
      isMismatchedNetwork,
      account,
      toggleWalletSelectModal,
      getProvider,
      appNetworkConfig
    } = useWeb3();
    const { fNum, toFiat } = useNumbers();
    const { t } = useI18n();
    const { minusSlippage } = useSlippage();
    const { tokens, balances: allBalances } = useTokens();
    const { trackGoal, Goals } = useFathom();
    const { txListener } = useEthers();
    const { addTransaction } = useTransactions();
    const { isStableLikePool, isWethPool, isWstETHPool } = usePool(
      toRef(props, 'pool')
    );

    const { amounts } = toRefs(data);

    const {
      requiredAllowances,
      approveAllowances,
      approving,
      approvedAll
    } = useTokenApprovals(props.pool.tokenAddresses, amounts);

    // SERVICES
    const poolExchange = computed(
      () => new PoolExchange(props.pool, appNetworkConfig.key, tokens.value)
    );

    const poolCalculator = new PoolCalculator(
      props.pool,
      tokens.value,
      allBalances,
      'join'
    );

    // COMPUTED
    const tokenWeights = computed(() =>
      Object.values(props.pool.onchain.tokens).map(t => t.weight)
    );

    const hasAmounts = computed(() => {
      const amountSum = fullAmounts.value
        .map(amount => parseFloat(amount))
        .reduce((a, b) => a + b, 0);
      return amountSum > 0;
    });

    const hasValidInputs = computed(() => {
      return data.validInputs.every(validInput => validInput === true);
    });

    const balances = computed(() => {
      return props.pool.tokenAddresses.map(
        token => allBalances.value[token] || '0'
      );
    });

    const hasZeroBalance = computed(() => {
      return balances.value.map(b => Number(b)).includes(0);
    });

    const total = computed(() => {
      const total = props.pool.tokenAddresses
        .map((_, i) => amountUSD(i))
        .reduce(
          (a, b) =>
            bnum(a)
              .plus(b)
              .toNumber(),
          0
        );

      if (total < 0) return fNum(0, 'usd');
      return fNum(total, 'usd');
    });

    const requireApproval = computed(() => {
      if (!hasAmounts.value) return false;
      if (approvedAll.value) return false;
      return requiredAllowances.value.length > 0;
    });

    const isProportional = computed(() => {
      return data.investType === FormTypes.proportional;
    });

    const propPercentage = computed(() => {
      const currentAmount = fullAmounts.value[data.propToken];
      const maxAmount = tokenBalance(data.propToken);

      if (currentAmount === '0') return 0;
      return Math.ceil((Number(currentAmount) / Number(maxAmount)) * 100);
    });

    const fullAmounts = computed(() => {
      return props.pool.tokenAddresses.map((_, i) => {
        return data.amounts[i] || '0';
      });
    });

    const propMaxUSD = computed(() => {
      const total = props.pool.tokenAddresses
        .map((token, i) => toFiat(data.propMax[i], token))
        .reduce(
          (a, b) =>
            bnum(a)
              .plus(b)
              .toNumber(),
          0
        );

      return fNum(total, 'usd');
    });

    const balanceMaxUSD = computed(() => {
      const total = props.pool.tokenAddresses
        .map((token, i) => toFiat(balances.value[i], token))
        .reduce(
          (a, b) =>
            bnum(a)
              .plus(b)
              .toNumber(),
          0
        );

      return fNum(total, 'usd');
    });

    const priceImpact = computed(() => {
      if (isProportional.value || !hasAmounts.value) return 0;
      return poolCalculator.priceImpact(fullAmounts.value).toNumber() || 0;
    });

    const priceImpactClasses = computed(() => {
      return {
        'text-red-500 font-medium': priceImpact.value >= 0.01,
        'text-gray-500 font-normal': priceImpact.value < 0.01
      };
    });

    const minBptOut = computed(() => {
      let bptOut = poolCalculator
        .exactTokensInForBPTOut(fullAmounts.value)
        .toString();
      bptOut = formatUnits(bptOut, props.pool.onchain.decimals);
      console.log(bptOut, `TS EVM _exactTokensInForBPTOut`);

      return minusSlippage(bptOut, props.pool.onchain.decimals);
    });

    const nativeAsset = computed(() => appNetworkConfig.nativeAsset.symbol);

    const formTypes = ref([
      {
        label: t('noPriceImpact'),
        max: propMaxUSD,
        value: FormTypes.proportional,
        tooltip: t('noPriceImpactTip')
      },
      {
        label: t('customAmounts'),
        max: balanceMaxUSD,
        value: FormTypes.custom,
        tooltip: t('customAmountsTip')
      }
    ]);

    // METHODS
    function tokenBalance(index: number): string {
      return balances.value[index] || '0';
    }

    function tokenDecimals(index) {
      return tokens.value[props.pool.tokenAddresses[index]].decimals;
    }

    function amountUSD(index) {
      const amount = fullAmounts.value[index] || '0';
      return toFiat(amount, props.pool.tokenAddresses[index]);
    }

    function formatBalance(index) {
      return fNum(tokenBalance(index), 'token');
    }

    function amountRules(index) {
      return isWalletReady.value
        ? [
            isPositive(),
            isLessThanOrEqualTo(
              Number(tokenBalance(index)),
              t('exceedsBalance')
            )
          ]
        : [isPositive()];
    }

    function symbolFor(token: string) {
      return tokens.value[token]?.symbol || '';
    }

    async function setPropMax() {
      const { send, fixedToken } = poolCalculator.propMax();
      data.propMax = [...send];
      data.propToken = fixedToken;
    }

    function resetSlider() {
      data.amounts = [...data.propMax];
      data.range = 1000;
    }

    function setPropAmountsFor(range) {
      const fractionBasisPoints = (range / 1000) * 10000;
      const amount = bnum(balances.value[data.propToken])
        .times(fractionBasisPoints)
        .div(10000)
        .toFixed(tokenDecimals(data.propToken));

      const { send } = poolCalculator.propAmountsGiven(
        amount,
        data.propToken,
        'send'
      );
      data.amounts = send;
    }

    // Legacy function for sense check against JS calculation of BPT out
    // Left here so numbers can be debugged in conosle
    // Talk to Fernando to see if still needed
    async function calcMinBptOut(): Promise<void> {
      let { bptOut } = await poolExchange.value.queryJoin(
        getProvider(),
        account.value,
        fullAmounts.value
      );
      bptOut = formatUnits(bptOut.toString(), props.pool.onchain.decimals);
      console.log(bptOut, 'bptOut (queryJoin)');
      console.log(minBptOut.value, 'bptOut (JS) minusSlippage');
      console.log(
        minusSlippage(bptOut, props.pool.onchain.decimals),
        'bptOut (queryJoin) minusSlippage'
      );
    }

    async function submit(): Promise<void> {
      if (!data.investForm.validate()) return;
      try {
        data.loading = true;
        await calcMinBptOut();
        const tx = await poolExchange.value.join(
          getProvider(),
          account.value,
          fullAmounts.value,
          minBptOut.value
        );
        console.log('Receipt', tx);

        addTransaction({
          id: tx.hash,
          type: 'tx',
          action: 'invest',
          summary: t('transactionSummary.investInPool', [
            total.value,
            getPoolWeights(props.pool)
          ]),
          details: {
            total,
            pool: props.pool
          }
        });

        txListener(tx, {
          onTxConfirmed: async (tx: TransactionResponse) => {
            emit('success', tx);
            data.amounts = [];
            data.loading = false;
            setPropMax();
            resetSlider();
          },
          onTxFailed: () => {
            data.loading = false;
          }
        });
      } catch (error) {
        console.error(error);
        data.loading = false;
      }
    }

    watch(tokens, newTokens => {
      poolCalculator.setAllTokens(newTokens);
    });

    watch(
      () => props.pool.onchain.tokens,
      (newTokens, oldTokens) => {
        poolCalculator.setPool(props.pool);
        const tokensChanged = !isEqual(newTokens, oldTokens);
        if (tokensChanged) {
          setPropMax();
          if (isProportional.value) setPropAmountsFor(data.range);
        }
      }
    );

    watch(balances, (newBalances, oldBalances) => {
      const balancesChanged = !isEqual(newBalances, oldBalances);
      if (balancesChanged) {
        setPropMax();
        if (isProportional.value) setPropAmountsFor(data.range);
      }
    });

    watch(
      () => data.investType,
      newType => {
        if (newType === FormTypes.proportional) {
          setPropMax();
          resetSlider();
        }
      }
    );

    watch(
      () => data.range,
      newVal => {
        setPropAmountsFor(newVal);
      }
    );

    watch(isWalletReady, isAuth => {
      if (!isAuth) {
        data.amounts = [];
        data.propMax = [];
      }
    });

    watch(account, () => {
      if (hasZeroBalance.value) {
        data.investType = FormTypes.custom;
      } else {
        setPropMax();
        resetSlider();
      }
    });

    onMounted(() => {
      if (hasZeroBalance.value) {
        data.investType = FormTypes.custom;
      } else {
        setPropMax();
        resetSlider();
      }
    });

    return {
      // data
      ...toRefs(data),
      Goals,
      nativeAsset,
      TOKENS,
      // computed
      tokens,
      appNetworkConfig,
      hasValidInputs,
      hasAmounts,
      approving,
      requireApproval,
      requiredAllowances,
      tokenWeights,
      tokenBalance,
      amountRules,
      total,
      isWalletReady,
      isMismatchedNetwork,
      toggleWalletSelectModal,
      formatBalance,
      isProportional,
      propPercentage,
      priceImpact,
      priceImpactClasses,
      amountUSD,
      formTypes,
      isRequired,
      hasZeroBalance,
      isWethPool,
      isWstETHPool,
      isStableLikePool,
      // methods
      submit,
      approveAllowances,
      fNum,
      trackGoal,
      symbolFor,
      tokenDecimals
    };
  }
});
