
import { defineComponent, PropType, ref } from 'vue';

export type Transaction = {
  title: string;
  handler: () => void;
};

export default defineComponent({
  emits: ['close', 'success'],
  props: {
    open: {
      type: Boolean,
      default: false
    },
    transactions: {
      type: Array as PropType<Transaction[]>,
      required: true
    }
  },
  setup(props, { emit }) {
    console.log(props.transactions);
    const currentTransaction = ref(0);
    const isExecuting = ref(false);

    function onClose() {
      emit('close');
    }

    async function confirm() {
      currentTransaction.value = 0;
      isExecuting.value = true;
      console.log('Got into "confirm"');
      try {
        console.log('Got into "confirm"');
        for (const transaction of props.transactions) {
          await transaction.handler();
          currentTransaction.value += 1;
        }
        emit('success');
      } catch (error) {
        console.error('Error during sequence execution', error);
      } finally {
        isExecuting.value = false;
      }
    }

    return {
      onClose,
      transactionList: props.transactions,
      currentTransaction,
      isExecuting,
      confirm
    };
  }
});
