
import useBreakpoints from '@/composables/useBreakpoints';
import useNumbers from '@/composables/useNumbers';
import useTokens from '@/composables/useTokens';
import { shortenLabel } from '@/lib/utils';
import { FullPool } from '@/services/balancer/subgraph/types';
import useWeb3 from '@/services/web3/useWeb3';
import numeral from 'numeral';
import { computed, defineComponent, PropType, Ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  props: {
    pool: {
      type: Object as PropType<FullPool>,
      required: true
    },
    loading: { type: Boolean, default: false }
  },
  setup(props) {
    /**
     * STATE
     */
    const { pool }: { pool: Ref<FullPool> } = toRefs(props);

    /**
     * COMPOSABLES
     */
    const { fNum } = useNumbers();
    const { explorerLinks } = useWeb3();
    const { t } = useI18n();
    const { upToLargeBreakpoint } = useBreakpoints();
    const { priceFor } = useTokens();

    /**
     * COMPUTED
     */
    const tableData = computed(() => {
      if (!pool || !pool.value || props.loading) return [];
      return Object.keys(pool.value.onchain.tokens).map((address, index) => ({
        address,
        index
      }));
    });

    const columns = computed(() => [
      {
        name: t('token'),
        id: 'token',
        accessor: 'address',
        Cell: 'tokenColumnCell',
        width: 175
      },
      {
        name: t('weight'),
        id: 'weight',
        accessor: 'index',
        Cell: 'tokenWeightCell',
        align: 'right',
        sortKey: pool => weightFor(pool.address),
        width: 125
      },
      {
        name: t('balance'),
        id: 'balance',
        accessor: 'index',
        Cell: 'tokenBalanceCell',
        align: 'right',
        sortKey: pool => balanceFor(pool.address),
        width: 125
      },
      {
        name: t('value'),
        id: 'value',
        accessor: 'index',
        Cell: 'tokenValueCell',
        align: 'right',
        sortKey: pool => numeral(fiatValueFor(pool.address)).value(),
        width: 125
      }
    ]);

    /**
     * METHODS
     */
    function symbolFor(address: string) {
      if (!pool || !pool.value) return '-';
      const symbol = pool.value.onchain.tokens[address].symbol;
      return symbol ? symbol : shortenLabel(address);
    }

    function balanceFor(address: string): string {
      if (!pool || !pool.value) return '-';
      return fNum(pool.value.onchain.tokens[address].balance, 'token');
    }

    function weightFor(address: string): string {
      if (!pool || !pool.value) return '-';
      return fNum(pool.value.onchain.tokens[address].weight, 'percent');
    }

    function fiatValueFor(address: string): string {
      const price = priceFor(address);
      if (!pool || !pool.value || price === 0) return '-';

      const balance = Number(pool.value.onchain.tokens[address].balance);
      return fNum(balance * price, 'usd');
    }

    return {
      symbolFor,
      balanceFor,
      weightFor,
      fiatValueFor,
      fNum,
      explorer: explorerLinks,
      columns,
      tableData,
      upToLargeBreakpoint
    };
  }
});
